.navigationBar__positionRelative {
    position: relative;
    height: 20vh;
}

.navigationBar {
    position: fixed;
    bottom: 5vh;
    left: 0;
    right: 0;
    z-index: 100;
}

.navTopSection {}

.navElements {
    background-color: #FFFFFF;
    margin: 0 auto;
    display: grid;
    margin: 0 2vw;
    padding: 1vh 32px;
    border-radius: 16px;
    gap: 12px;
    box-shadow: 0 0.0625rem 0.1875rem rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0 0.625rem 0.9375rem -0.3125rem, rgb(0 0 0 / 4%) 0 0.4375rem 0.4375rem -0.3125rem;
}

.navButton {
    padding: 1vh;
    margin: 0;
    height: 100%;
    width: 100%;
}

.navElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.navElement__icon {
    height: 24px;
    width: 24px;
}

.navElement__icon>svg {
    width: 100%;
    height: 100%;
    color: #868E96;
}

.navElement__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #868E96;
}

@media (orientation: portrait) {}

@media (orientation: landscape) {
    .navElements {
        max-width: 600px;
        margin: 0 auto;
    }
}