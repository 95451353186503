.city {
    font-family: 'Mona Sans';
    font-style: normal;
    font-size: 14px;
    width: 150px;
}

.map_icon {
    width: 32px;
    height: 32px;
    align-content: center;
    align-self: center;
}