.defaultPage {
    padding: 0 3vw;
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

@media (orientation: landscape) {
    .defaultPage {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}