.ItemPage {
  font-family: "Roboto Flex";
}

@media (orientation: landscape) {
  .ItemPage__header {
    flex-direction: row-reverse;
    justify-content: space-between;
    column-gap: 2vh;
  }

  .ItemPage__image {
    flex: 1 1 30%;
  }

  .ItemPage__image img {
    height: 360px !important;
  }

  .ItemPage__text {
    flex: 0 1 30%;
    display: flex;
    justify-content: center;
  }
}

.clamped {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
  max-height: 42px;
  overflow: hidden;
}
