.mantine-Carousel-root {
    background-color: #FFFF;
}

.slide {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.firstSlideContainer {
    background: rgb(195, 34, 113);
    background: linear-gradient(0deg, rgba(195, 34, 113, 1) 0%, rgba(174, 45, 253, 1) 100%);
}

.welcomeScreen {
    background-color: #653dc5;
}

.textContainer {
    font-family: 'Unbounded', cursive;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.subtitle {
    font-weight: 200;
    font-size: 20px;
    line-height: 100%;
    color: #E5DBFF;
}

.title {
    color: #FFFFFF;
    font-weight: 900;
    font-size: 2rem;
    line-height: 155%;

}

.buttonContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 11vh;
    z-index: 100;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    align-self: center;
    width: 100%;
}

.buttonContainer>div,
.buttonContainer>a {
    width: 100%;
}

/* фон первого экрана */
.background {
    position: relative;
}

.rect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
}

.rectCenter {
    background: rgb(183, 153, 179) no-repeat;
    background: radial-gradient(circle, rgba(183, 153, 179, 1) 0%, rgba(238, 232, 238, 0) 71%);
    background-size: 150vw 150vh;
    background-position: 20% 60%;
}

.rectEdgeBottom {
    background: #FFFF no-repeat;
    background: radial-gradient(circle, rgba(183, 153, 179, 70%) 5%, rgba(238, 232, 238, 0) 30%);
    background-size: 200vw 150vh;
    background-position: 10% -50%;
}

.rectEdgeTop {
    background: #FFFF no-repeat;
    background: radial-gradient(circle, rgba(183, 153, 179, 70%) 5%, rgba(238, 232, 238, 0) 30%);
    background-size: 200vw 150vh;
    background-position: 110% 150%;
}

.rectYellow {
    background: radial-gradient(circle, rgba(255, 201, 10, 0.4) 0%, rgba(238, 232, 238, 0) 30%);
    background-size: 150vw 150vh;
    background-position: 60% 60%;
}

.rectPink {
    background: radial-gradient(circle, rgba(252, 70, 223, 0.7099767981438515) 0%, rgba(238, 232, 238, 0) 33%);
    background-size: 150vw 150vh;
    background-position: 20% 40%;
}

/* стили второго слайда */
.slide.CityScreen {
    height: auto;
    max-height: 95%;
}

.CityScreen {
    font-family: 'Roboto Flex';
    padding: 20px;
    gap: 3vh;
}

.CityScreen .buttonContainer {
    padding-bottom: 0;
}

.map {
    flex: 1 1 auto;
    overflow: hidden;
    border-radius: 0.5rem;
    height: 60vh;
    position: relative;
}

.map::after {
    /* content: ''; */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.marker {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
}

.mapMarkerKalkan {
    right: 50%;
}

.mapMarkerKas {
    left: 50%;
}

.map iframe {
    /* display: none; */
    margin-top: -70px;
}

iframe {
    height: calc(100% + 70px);
}

.info {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.info__title {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    color: #212529;
}

.info__text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 155%;
    color: #495057;
}

@media (orientation: landscape) {
    .slide {
        height: 100%;
        max-height: unset;
    }

    .buttonContainer {
        max-width: 350px;
    }
}

@media (orientation: portrait) {}