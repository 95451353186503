.navigationText {
  width: 100%;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.navigationText__title {
  font-weight: 700;
  font-size: 26px;
  line-height: 135%;
  color: #212529;
  display: flex;
  align-items: center;
  gap: 16px;
}

.navigationText__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #495057;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
  max-height: 42px;
  overflow: hidden;
}

.navigationText__arrow {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
