.image {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  height: 100vh;
}

.heading {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  font-family: "Mona Sans, Roboto Flex", sans-serif;
}

.section-heading {
  font-family: "Mona Sans, Roboto Flex", sans-serif;
}

.text {
  font-family: "Mona Sans, Roboto Flex", sans-serif;
  font-size: 1rem;
  line-height: 1.4rem;
}

.left-column,
.right-column {
  box-sizing: border-box;
}

.left-column {
  width: inherit;
}


.right-column {
}

.right-column .first-div p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* overflow: hidden; */
  margin-bottom: 16px;
}


.vertical-sections {
  display: flex;
  justify-content: space-between;
}

.vertical-sections > div {
  display: flex;
  align-items: flex-start;
}

.vertical-sections > div:not(:last-child):after {
  content: "";
  display: block;
  width: 1px;
  margin: 0 16px;
  height: 164px;
  background-color: #ced4da;
}

.button {
  display: block;
}

.section-image {
  align-self: center;
  width: 96;
  height: 96;
}

.font {
  font-family: "Mona Sans, Roboto Flex", sans-serif;
}
.media {
  flex-wrap: nowrap;
  height: 100vh
}
/* @media (max-width: px) {
  .media {
    flex-wrap: wrap;
    height: auto;
  }
} */
