#root {
  height: 100vh;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

}

body {
  margin: 0;
  font-family: "Mona Sans, Unbounded", cursive;
  font-family: "Mona Sans, Roboto Flex", sans-serif;
  line-height: 1;
}
