.MainPage {
  height: 100%;
}

.mainCard__title {
  font-family: "Roboto Flex";
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  color: #343a40;
}

.mainCard__text {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #909296;
}

.mainCard__icon {
  color: #fd7e14;
}

/* baseCard */
/* todo: вынести стили базовой карточки в отдельный компонент */
.BaseCArd__tag {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  text-transform: none;
}

@media (orientation: landscape) {
  .mainCard:last-child {
    margin-bottom: unset;
    justify-content: space-between;
  }
}
