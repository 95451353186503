.header {
    padding: 0 3vw;
    display: flex;
    justify-content: center;
    line-height: 1;
}
.headerRow {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* max-width: 1920px; */
}
.controlElements {
    display: flex;
    align-items: center;
    gap: 24px;
}