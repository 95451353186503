.MainPage {
    height: 100%;
}

.mainCard {}

/* .mainCard__image img {
    background: transparent;
    background-image: linear-gradient(to left, #11de93, #0e7ad6);
} */

.mainCard__title {
    font-family: 'Roboto Flex';
    font-weight: 700;
    font-size: 22px;
    line-height: 140%;
    color: #343A40;
}

.mainCard__container {
    display: flex;
    gap: 16px;
}

.mainCard__text {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #909296;
}

.mainCard__icon {
    color: #FD7E14;
}

@media (orientation: landscape) {
    .mainCard {
        max-width: 20%;
        min-width: 300px;
    }
}